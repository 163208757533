import store from '@/store'
import Presenter from '@/components/pages/manage/item/create/Presenter'

export default {
  render (h) {
    return h(Presenter, {
      props: {
        categories: [
          { name: '会食', val: 100 },
          { name: '食品', val: 200 },
          { name: '雑貨', val: 300 },
          { name: '健康・美容', val: 400 },
          { name: 'レジャー', val: 500 }
        ]
      },

      on: {
        async submit (presenter) {
          // validation
          if (!presenter.$refs.form.validate()) {
            return
          }
          if (!presenter.mainImg) {
            store.commit('message/show', {
              message: 'メイン画像は設定が必須です。',
              color: 'error',
              timeout: 4000
            })
            return
          }
          // process
          presenter.$store.commit('updateLoading', { flag: true })
          const formData = new FormData()
          formData.append('name', presenter.name)
          formData.append('description', presenter.description)
          formData.append('precaution', presenter.precaution)
          formData.append('price', presenter.price)
          formData.append('category', presenter.category)
          formData.append('expirationPeriod', presenter.expirationPeriod)
          formData.append('main_img', presenter.mainImg)
          formData.append('sub_img1', presenter.subImg1)
          formData.append('sub_img2', presenter.subImg2)
          formData.append('sub_img3', presenter.subImg3)
          formData.append('sub_img4', presenter.subImg4)
          const res = await presenter.$store.dispatch('item/create', formData)
          store.commit('message/show', {
            message: 'アイテムを作成しました。',
            color: 'info',
            timeout: 4000
          })
          presenter.$router.push({ name: 'ManageItemDetail', params: { uuid: res.uuid }})
        }
      }
    })
  }
}
