<template>
  <v-container
    class="mb-6"
    style="max-width: 1000px"
  >
    <div class="mb-5">
      アイテム登録
    </div>
    <v-row>
      <v-col>
        <v-card
          :class="['outset', 'welfare-card', 'px-10', 'py-10']"
        >
          <v-form
            ref="form"
            v-model="valid"
            @submit.prevent="submit"
          >
            <v-text-field
              v-model="name"
              label="アイテム名"
              :counter="300"
              :rules="[required]"
            />
            <v-textarea
              v-model="description"
              label="説明"
              rows="4"
            />
            <v-textarea
              v-model="precaution"
              label="注意事項 (省略可)"
              rows="4"
            />
            <v-text-field
              v-model="price"
              label="ポイント数"
              type="number"
              :rules="[required]"
            />
            <v-select
              v-model="category"
              :items="categories"
              label="アイテムカテゴリー"
              item-text="name"
              item-value="val"
              :rules="[required]"
            />
            <v-text-field
              v-model="expirationPeriod"
              label="有効期間(日)"
              type="number"
            />
            <div class="img-upsert-div">
              メイン画像
              <v-img
                v-if="mainImgPreviewUrl"
                :src="mainImgPreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <v-file-input
                hide-input
                prepend-icon="mdi-camera-plus"
                accept="image/*"
                @change="onChangeImg($event, 'main')"
              />
            </div>
            <div class="img-upsert-div">
              サブ画像1
              <v-img
                v-if="subImg1PreviewUrl"
                :src="subImg1PreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <v-file-input
                hide-input
                prepend-icon="mdi-camera-plus"
                accept="image/*"
                @change="onChangeImg($event, 'sub1')"
              />
            </div>
            <div class="img-upsert-div">
              サブ画像2
              <v-img
                v-if="subImg2PreviewUrl"
                :src="subImg2PreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <v-file-input
                hide-input
                prepend-icon="mdi-camera-plus"
                accept="image/*"
                @change="onChangeImg($event, 'sub2')"
              />
            </div>
            <div class="img-upsert-div">
              サブ画像3
              <v-img
                v-if="subImg3PreviewUrl"
                :src="subImg3PreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <v-file-input
                hide-input
                prepend-icon="mdi-camera-plus"
                accept="image/*"
                @change="onChangeImg($event, 'sub3')"
              />
            </div>
            <div class="img-upsert-div">
              サブ画像4
              <v-img
                v-if="subImg4PreviewUrl"
                :src="subImg4PreviewUrl"
                contain
                height="250"
                width="250"
              />
              <v-img
                v-else
                src="@/assets/img/no_image.png"
                contain
                height="250"
                width="250"
              />
              <v-file-input
                hide-input
                prepend-icon="mdi-camera-plus"
                accept="image/*"
                @change="onChangeImg($event, 'sub4')"
              />
            </div>
            <v-row justify="center">
              <v-btn
                rounded
                type="submit"
                color="primary"
                :class="['small-outset', 'mt-7']"
              >
                登録する
              </v-btn>
            </v-row>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: {
    categories: {
      type: Array,
      default: () => []
    }
  },

  data: () => ({
    valid: false,
    name: '',
    description: '',
    precaution: '',
    price: 0,
    expirationPeriod: 0,
    mainImg: '',
    subImg1: '',
    subImg2: '',
    subImg3: '',
    subImg4: '',
    category: '',
    mainImgPreviewUrl: '',
    subImg1PreviewUrl: '',
    subImg2PreviewUrl: '',
    subImg3PreviewUrl: '',
    subImg4PreviewUrl: '',
    required: value => !!value || 'この項目は入力が必須です'
  }),

  methods: {
    submit () {
      this.$emit('submit', this)
    },
    onChangeImg (file, imgType) {
      switch (imgType) {
        case 'main':
          this.mainImg = file
          this.mainImgPreviewUrl = URL.createObjectURL(file)
          break
        case 'sub1':
          this.subImg1 = file
          this.subImg1PreviewUrl = URL.createObjectURL(file)
          break
        case 'sub2':
          this.subImg2 = file
          this.subImg2PreviewUrl = URL.createObjectURL(file)
          break
        case 'sub3':
          this.subImg3 = file
          this.subImg3PreviewUrl = URL.createObjectURL(file)
          break
        case 'sub4':
          this.subImg4 = file
          this.subImg4PreviewUrl = URL.createObjectURL(file)
          break
      }
    }
  }
}
</script>
